<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Trigger" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="item">
      <h2 class="mb-2">Messages</h2>
      <data-table-ssr v-if="item" id="trigger_list" ref="trigger_list" :pagination="false"
        :columns="message_fields" :get-list-fn="getMessages"
        :to-edit-fn="toEditMessage" :delete-row-fn="deleteMessage"
      />
      <div class="d-flex justify-content-center my-2">
        <data-create-popup title="Create Message" :schema="message_form_schema"
          :default-data="message_default"
          :create-data-fn="createMessage"
        />
      </div>
      <data-update-popup ref="message_edit_popup" title="Update Message" :schema="message_form_schema"
        :default-data="editing_data" :update-data-fn="updateMessage"
      />
    </section>

    <section class="mb-2" v-if="item">
      <b-card>
        <h4 class="mb-2">Apply time</h4>
        <data-table-ssr
          v-if="item"
          id="apply_time"
          ref="apply_time"
          :pagination="false"
          :columns="apply_time.fields"
          :get-list-fn="getApplyTime"
          :to-edit-fn="toEditApplyTime"
          :delete-row-fn="deleteApplyTime"
        />
        <div class="d-flex justify-content-center my-2">
          <data-create-popup
            title="Create Apply Time"
            :schema="apply_time.schema"
            :default-data="apply_time.default_data"
            :create-data-fn="createApplyTime"
          />
        </div>
        <data-update-popup
          ref="apply_time_edit_popup"
          title="Update Message"
          :schema="apply_time.schema"
          :default-data="editing_data"
          :update-data-fn="updateApplyTime"
        />
      </b-card>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import { PUSH_TYPE_OPTIONS, PUSH_TYPE } from './../constant';

const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Key', field: 'key', input_type: 'text', validate: { required: true }  },
      { label: 'Description', field: 'description', input_type: 'textarea' },
      { label: 'Recover Days', field: 'recover_time', input_type: 'number', validate: { type: 'number', min: 0 } },
    ]
  }
];

const apply_time = {
  default_data: {
    min: 0,
    max: 0,
  },
  schema: [{
    cols: 12,
    fields: [
      { label: 'Min', field: 'min', input_type: 'number', validate: { type: 'num' }},
      { label: 'Max', field: 'max', input_type: 'number', validate: { type: 'num' }},
    ]
  }],
  fields: [
    { label: 'Min' , field: 'min', input_type: 'number', validate: { type: 'num' }},
    { label: 'Max' , field: 'max', input_type: 'number', validate: { type: 'num' }},
  ]
}

const message_default = {
  remote: true,
  title: { type: 'translatable-text' },
  body: { type: 'translatable-text' },
  push_type: 1
};

const message_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', input_type: 'ui-component' },
      { label: 'Body', field: 'body', input_type: 'ui-component' },
      { label: 'Redirect (optional)', field: 'redirect', input_type: 'text' },
      { label: 'Push Time', field: 'push_type', input_type: 'select', options: PUSH_TYPE_OPTIONS },
    ],
  },
  {
    cols: 12,
    displayCond(model) { return model.push_type === PUSH_TYPE.DELAY },
    fields: [
      { label: 'Delay Days', field: 'delay_days', input_type: 'number' },
      { label: 'At Time', field: 'at_time', input_type: 'time' },
    ],
  }
];
const message_fields = [
  { label: 'Title', field: 'title', input_type: 'ui-component', display_type: 'translatable-text' },
  { label: 'Body', field: 'body', input_type: 'ui-component', display_type: 'translatable-text' },
  { label: 'Push Time', field: 'push_type', input_type: 'select', options: PUSH_TYPE_OPTIONS },
  { label: 'Delay Days', field: 'delay_days', input_type: 'number' },
  { label: 'At Time', field: 'at_time', input_type: 'time' },
  { label: 'Redirect', field: 'redirect', input_type: 'text' },
];

export default {
  data(){
    return{
      item_form_schema,
      message_fields,
      message_default,
      message_form_schema,
      apply_time,
      item: null,
      isUpdating: false,
      isDeleting: false,
      triggers: null,
      editing_data: null,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.trigger_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Noti Groups',
          to: { name: 'noti-group-list' },
        },
        {
          text: 'Group',
          to: { name: 'noti-group-detail', params: { id: this.$route.params.id } },
        },
        {
          text: `${this.item ? this.item.key : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
  },
  methods:{
    // item
    async getItem(){
      this.item = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.isUpdating = false;
      await this.getItem();
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "noti-group-detail", params: { id: this.$route.params.id } });
    },
    // message
    async createMessage(data) {
      this.item.messages.push(data);
      await this.update();
      this.reloadMessageList();
      return true;
    },
    async getMessages({ limit, page, query, searchTerm }){
      let list = [], total = 0;
      list = [...this.item.messages];
      total = list.length;
      return { list, total };
    },
    reloadMessageList() {
      this.$refs.trigger_list.getList();
    },
    toEditMessage(data) {
      this.editing_data = data;
      this.$refs.message_edit_popup.startEdit();
    },
    async updateMessage(data) {
      let index = data.originalIndex;
      this.item.messages[index] = { ...data };
      await this.update();
      this.reloadMessageList();
    },
    async deleteMessage(data) {
      let index = data.originalIndex;
      this.item.messages.splice(index, 1);
      await this.update();
      this.reloadMessageList();
    },
    reloadApplyTimeList() {
      this.$refs.apply_time.getList();
    },
    async createApplyTime(data) {
      this.item.apply_time.push(data);
      await this.update();
      this.reloadApplyTimeList();
      return true;
    },
    async getApplyTime({ limit, page, query, searchTerm }){
      let list = [], total = 0;
      list = [...this.item.apply_time];
      total = list.length;
      return { list, total };
    },
    toEditApplyTime(data) {
      this.editing_data = data;
      this.$refs.apply_time_edit_popup.startEdit();
    },
    async updateApplyTime(data) {
      let index = data.originalIndex;
      this.item.apply_time[index] = { ...data };
      await this.update();
      this.reloadMessageList();
    },
    async deleteApplyTime(data) {
      let index = data.originalIndex;
      this.item.apply_time.splice(index, 1);
      await this.update();
      this.reloadMessageList();
    },
  }
}
</script>
